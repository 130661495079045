import React, { useState, useEffect } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Row, Col, Card, NavDropdown } from 'react-bootstrap';
import { graphql, useStaticQuery } from 'gatsby';
import { Link } from "@StarberryUtils";
import _ from "lodash";
import './Teams.scss';
import ImageTransform from '../../common/ggfx-client/module/components/image-transform';
import CallIcon from "./call.png";

function Teams(props) {

    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                teams(sort: "Sort", where: {Publish: true}) {
                    Email
                    Designation
                    Calendly_Link
                    About
                    Name
                    Meta_Title
                    Meta_Description
                    Phone
                    Sort
                    URL
                    _id
                    id
                    Full_Image {
                        url
                        alternativeText
                    }
                    Tile_Image {
                        url
                        alternativeText
                    }
                    team_departments {
                        Title
                        URL
                    }
                    our_offices {
                        Title
                        URL
                    }
                }

                offices(sort:"Sort") {
                    _id
                    Title
                    URL
                    Description
                }

                teamDepartments {
                    Title
                    URL
                    Sort
                }
            }
        }
    `);

    console.log("text", data.glstrapi.teamDepartments);

    const [query, setQuery] = React.useState("");
    const [category, setCategory] = React.useState(null);
    const [categoryname, setCategoryName] = React.useState("All Offices");
    const [categoryUrl, setCategoryUrl] = React.useState("all-offices");
    const [departmentname, setDepartmentName] = React.useState("All Departments");
    const [departmentUrl, setDepartmentUrl] = React.useState("all-departments");
    const [offices, setAddresses] = React.useState([]);
    const [filteredTeam, setFilteredTeam] = useState([]);
    useEffect(() => {
        // console.log("xxx",categoryname)
        // console.log("xxx",departmentname)
        var teams = data.glstrapi.teams;
        if (categoryUrl !== "all-offices" && departmentUrl === "all-departments") {
            teams = teams.filter(team => {
                let category_1 = _.includes(_.map(team.our_offices, 'URL'), categoryUrl)
                if (category_1) {
                    return category_1
                }
            });
        }
        else if (categoryUrl === "all-offices" && departmentUrl !== "all-departments") {
            teams = teams.filter(team => {
                let category_1 = _.includes(_.map(team.team_departments, 'URL'), departmentUrl)
                if (category_1) {
                    return category_1
                }
            });
        }
        else if (categoryUrl !== "all-offices" && departmentUrl !== "all-departments") {
            teams = teams.filter(team => {
                let category_1 = _.includes(_.map(team.our_offices, 'URL'), categoryUrl)
                let category_2 = _.includes(_.map(team.team_departments, 'URL'), departmentUrl)
                if (category_1 && category_2) {
                    return category_1
                }
            });
        }
        else {
            teams = data.glstrapi.teams;
        }


        setFilteredTeam(teams)
    }, [data, categoryUrl, departmentUrl]);



    const Categories = (props) => {
        const offices = data.glstrapi.offices;
        return (


            <div className="d-flex flex-row justify-content-between button-text-boxes">
                <div class="form-select-boxes">
                    <NavDropdown className="form-control" title={categoryname} id="nav-dropdown office-name-dropdown-title">
                        <NavDropdown.Item eventKey={`0`} onClick={() => {
                            setCategoryName("All Office");
                            setCategoryUrl("all-offices")
                        }} data-filter={`all`} >All Offices</NavDropdown.Item>

                        {offices?.map((listoffices, index) =>
                            <NavDropdown.Item eventKey={index + 1} onClick={() => {
                                setCategoryUrl(listoffices.URL);
                                setCategoryName(listoffices.Title);
                            }} data-filter={listoffices.URL} >{listoffices.Title}</NavDropdown.Item>
                        )}
                    </NavDropdown>

                </div>

                <div class="form-select-boxes">

                    <NavDropdown className="form-control" title={departmentname} id="nav-dropdown office-name-dropdown-title">
                        <NavDropdown.Item eventKey={`0`} onClick={() => {
                            setDepartmentName("All Departments");
                            setDepartmentUrl("all-departments");
                        }} data-filter={`all`} >All Departments</NavDropdown.Item>

                        {data.glstrapi.teamDepartments?.map((listdept, index) =>
                            <NavDropdown.Item eventKey={index + 1} onClick={() => {
                                setDepartmentUrl(listdept.URL);
                                setDepartmentName(listdept.Title);
                            }} data-filter={listdept.URL} >{listdept.Title}</NavDropdown.Item>
                        )}
                    </NavDropdown>

                </div>
            </div>
        )
    }



    const ImageRender = ({ image_url, node, processedImages }) => {
        return (
            <ImageTransform
                imagename="teams.Tile_Image.tile"
                attr={{ alt: node.Tile_Image.alternativeText, className: '' }}
                imagesources={image_url}
                renderer="srcSet"
                imagetransformresult={processedImages}
                id={node.id}
            />

        )
    }

    return (
        <>
            <div className="container-fluid">
                <Row className="justify-content-between menu-list row align-items-center">
                    <Col xs={12} md={8} lg={8} xl={8} className="sold-prop-list team-list-grid">
                        <Categories
                            offices={offices}
                            setCategory={setCategory}
                            category={category}
                            handleChange={(val) => setQuery(val)}
                            query={query}
                        />
                    </Col>
                </Row>
            </div>

            <Container className="team-list">
                <Row className="justify-content-sm-start justify-content-center mb-lg-5 mb-0 pb-4">
                    {filteredTeam && filteredTeam.length > 0 ?
                        <>
                            {filteredTeam.sort((a, b) => a.Sort - b.Sort).map((each, i) => {
                                let processedImages = JSON.stringify({});
                                if (each?.imagetransforms?.Tile_Image_Transforms) {
                                    processedImages = each.imagetransforms.Tile_Image_Transforms;
                                }
                                return (
                                    <Col xl={3} lg={4} sm={6} xs={11} key={i}>
                                        <ScrollAnimation animateIn={i % 2 === 0 ? 'fadeInLeft' : 'fadeInRight'} className="my-2 overflow-hidden" animateOnce>
                                            <Card className="w-100 mb-2">
                                                {each.Tile_Image &&
                                                    <Link to={`/about/our-team/${each.URL}`}>
                                                        <div className="card-img-bg">
                                                            <ImageRender processedImages={processedImages} image_url={each.Tile_Image.url} node={each} />

                                                        </div>
                                                    </Link>
                                                }
                                                <Card.Body>
                                                    <Link to={`/about/our-team/${each.URL}`}>
                                                        <Card.Title className="mb-0">{each.Name}</Card.Title>
                                                        <Card.Text>{each.Designation}</Card.Text>
                                                    </Link>
                                                    <div className="mt-2">
                                                        <a href={`tel:${each.Phone}`} className="d-flex flex-row" onClick={(e) => e.stopPropagation()}>
                                                            <img src={CallIcon} className="mr-2 mt-1" width="15" height="15" />
                                                            <Card.Text>{each.Phone}</Card.Text>
                                                        </a>

                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </ScrollAnimation>
                                    </Col>
                                )
                            }
                            )}
                        </> : <div className="team-wrapper col-12"><h3>No Results Found</h3></div>}
                </Row>
            </Container>
        </>
    )
}

export default Teams;