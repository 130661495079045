import React, { useState } from "react"
import { Link } from "@StarberryUtils"
import HTMLReactParser from 'html-react-parser';
import './StaticBanner.scss'
import PlayVideo from "../../Play/custom-video";
import { Modal } from "react-bootstrap";
import businessimg from '../../../images/logo.png'
import guidline from '../../../images/static/guidline.png'
import ScrollAnimation from 'react-animate-on-scroll'
import { GetURL } from "../../common/site/functions";
import ContactUsForm from "../../forms/contact-us-form";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const containerVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.4,
            ease: [0.33, 1, 0.68, 1],
            duration: 0.4
        }
    }
}

const contentVariants = {
    hidden: {
        opacity: 0,
        y: 40
    },
    visible: {
        opacity: 1,
        y: 0,
        delay: 0.4,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 1
        }
    }
}

const StaticBanner = (props) => {
    const [isPlay, setPlay] = useState(false);
    const [showForm, setShowForm] = React.useState(false);
    const [email, setEmail] = React.useState("");
    let url = ''
    if (props.link) {
        url = GetURL(props.link._id)
    }

    let processedImages = JSON.stringify({});
    if (props.imagetransforms?.Banner_Image_Transforms) {
        processedImages = props.imagetransforms.Banner_Image_Transforms;
    }

    return (
        <InView threshold={0}>
            {({ ref, inView }) => (
                <motion.section className="property-card static-banner landing-page-static-banner"
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={containerVariants}
                >
                    <ScrollAnimation animateIn='fadeInRight' className="product-card-img img-zoom" animateOnce>
                        {props.image &&
                            <ImageTransform
                                imagesources={props.image}
                                renderer="srcSet" imagename="articles.Banner_Image.details"
                                attr={{ alt: props.image_alt, className: '' }}
                                imagetransformresult={processedImages}
                                id={props.id}
                            />
                        }

                        {props.video &&
                            <div className="product-btn">
                                <PlayVideo url={props.video} />
                            </div>
                        }

                    </ScrollAnimation>
                    <ScrollAnimation animateIn='fadeInLeft' className="product-card-details" animateOnce={true}>
                        <motion.div variants={contentVariants}>
                            <ScrollAnimation animateIn='fadeInLeft' animateOnce={true}>
                                {HTMLReactParser(props.content.replace(/\n/g, '<br />'))}

                                {props.label && url &&
                                    <div className="buttons">
                                        {(url == "contact" && props.title == "Movies") ?
                                            <a onClick={() => setShowForm(true)} className="btn btn-primary">
                                                {props.label}
                                            </a> :
                                            <Link to={`/${url}`} className="btn btn-primary">
                                                {props.label}
                                            </Link>
                                        }
                                    </div>
                                }

                                {props.contact ?
                                    <div className="images">
                                        <div className="author-img">
                                            {props.contact.Tile_Image ?
                                                <img src={props.contact.Tile_Image.url} alt="img" /> :
                                                <img src={businessimg} alt="img" />
                                            }
                                        </div>
                                        <div className="about-img">
                                            <a onClick={() => { setEmail(props.contact.Email); setShowForm(true); }} className="mail">{props.contact.Email}</a>
                                            <a href={`tel:+${props.contact.Phone}`} className="tel">{props.contact.Phone}</a>
                                        </div>
                                    </div> :
                                    <div className="images">
                                        <div className="author-img">
                                            <figure>
                                                <picture>
                                                    <source media="(min-width:1200px)" srcSet={guidline} />
                                                    <img src={businessimg} alt="img" />
                                                </picture>
                                            </figure>
                                        </div>
                                        <div className="about-img">
                                            <a onClick={() => { setEmail("hello@bovingdons.com"); setShowForm(true); }} className="mail">hello@bovingdons.com</a>
                                            <a href="tel:+01494 677 733" className="tel">01494 677 733</a>
                                        </div>
                                    </div>
                                }
                            </ScrollAnimation>
                        </motion.div>

                    </ScrollAnimation>
                    <Modal show={showForm} onHide={() => setShowForm(false)} className="enquire-form contact-modal" centered>
                        <Modal.Header closeButton>
                            <Modal.Title className="px-0">Contact us</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ContactUsForm email={email}/>
                        </Modal.Body>
                    </Modal>
                </motion.section>
            )}
        </InView>
    )
};

export default StaticBanner