import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import SellProduct from "../../SearchResult/SellProduct/SellProduct";
import './Banner.scss';

const Banner = (props) => {
    const [isPlay, setPlay] = React.useState(false);
    const [showPause, setShowPause] = React.useState(false);
    const playVideo = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'formClick',
            'formType': 'form-video',
            'formId': 'form-banner-video',
            'formName': "Banner Video",
            'formLabel': window.location.href
        });
        setPlay(true);
        videoRef.current.play();
    }
    const closeVideo = () => {
        setPlay(false);
        videoRef.current.pause();
    }
    const videoRef = React.createRef(null);
    const videoConRef = React.createRef(null);

    let processedImages = JSON.stringify({});
    if (props.imagetransforms?.Banner_Image_Transforms) {
        processedImages = props.imagetransforms.Banner_Image_Transforms;
    }

    const data = useStaticQuery(graphql`
        query holidaysQuery {  
            glstrapi {
                siteConfig {
                    Chrismas_Holidays {
                        CTA_Block_Description
                        CTA_Block_Label
                        CTA_Block_Link {
                            URL
                            id
                        }
                        CTA_Block_Title
                        id
                    }
                }
            }   
        }
    `)

    return (
        <React.Fragment>
             {(props?.page?.Show_Holidays_Banner) && <SellProduct
                title={data?.glstrapi?.siteConfig?.Chrismas_Holidays?.CTA_Block_Title} 
                label={data?.glstrapi?.siteConfig?.Chrismas_Holidays?.CTA_Block_Label} 
                link={data?.glstrapi?.siteConfig?.Chrismas_Holidays?.CTA_Block_Link} 
                desc={data?.glstrapi?.siteConfig?.Chrismas_Holidays?.CTA_Block_Description} 
                class="val-cta-block" 
            />}
            <section className="banner">
                <div className="full-banner-image-container">
                    <div className="full-banner-image">
                        {/* {props.image &&
                            <ImageTransform
                                imagesources={props.image}
                                renderer="srcSet" imagename="articles.Banner_Image.banner"
                                attr={{ alt: props.image.alternativeText, class: '' }}
                                imagetransformresult={processedImages}
                                id={props.id}
                            />
                        } */}
                        <h1 className="d-none">Bovingdons Real Estate Agents</h1>
                    </div>
                </div>
                {(props.video) && 
                    <div className="video-container" ref={videoConRef}>
                        <video width="100%" height="100%" autoPlay muted={!isPlay} playsInline loop className="video-banner" ref={videoRef} controls={isPlay} onPauseCapture={() => closeVideo()}>
                            <source src={props.video.url} type="video/mp4"/>
                        </video>
                        {(props.video && !isPlay) &&
                            <div className="banner-btn">
                                <strong 
                                    className="video-btn" 
                                    onClick={playVideo}
                                >
                                    <i className="icon-video" />
                                </strong>
                            </div>
                        }
                        {(props.video && isPlay) &&
                            <div className="banner-btn pause-btn d-md-flex d-none" onMouseEnter={() => setShowPause(true)} onMouseLeave={() => setShowPause(false)}>
                                {showPause && <strong 
                                    className="video-btn" 
                                    onClick={closeVideo}
                                >
                                    <i className="icon-pause" />
                                </strong>}
                            </div>
                        }

                        {/* {isPlay && <div className="btn btn-light close-icon" onClick={closeVideo}>
                            <img src={closeIcon} alt="close-icon"/>
                        </div>} */}
                    </div>
                }
            </section>
           
        </React.Fragment>

    )
};
export default Banner;