import React from "react";
import { Link } from "@StarberryUtils"
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col, Modal } from "react-bootstrap";
import Map from '../map/office-details-map'
import Form from '../forms/contact'
import HTMLReactParser from 'html-react-parser';
import ScrollAnimation from 'react-animate-on-scroll';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import ContactUsForm from "../forms/contact-us-form";

const containerVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.4,
            ease: [0.33, 1, 0.68, 1],
            duration: 0.4
        }
    }
}

const contentVariants = {
    hidden: {
        opacity: 0,
        y: 40
    },
    visible: {
        opacity: 1,
        y: 0,
        delay: 0.4,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 1
        }
    }
}

const OfficePage = () => {
    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                offices(sort: "Sort") {
                    Title
                    Sub_Title
                    Address
                    Mobile
                    Image {
                      url
                    }
                    URL
                }
            }
        }
    `);

    const page = data?.glstrapi?.offices;
    const [showForm, setShowForm] = React.useState(false);
    const [email, setEmail] = React.useState("");

    // console.log('off', office);

    return (
        <div>

            <InView threshold={0.2}>
                {({ ref, inView }) => {
                    return (
                        <motion.div className="contact-page-content office-page-content"
                            ref={ref}
                            initial="hidden"
                            animate={inView ? "visible" : "hidden"}
                            variants={containerVariants}
                        >
                            <Container>
                                <Row>
                                    {data.glstrapi.offices.map((office, a) => {
                                        return (
                                            <Col className="col-6 block-1 office-details-grid">
                                                <motion.div variants={contentVariants}>
                                                    <ScrollAnimation animateIn='fadeInLeft' animateOnce>
                                                        <>
                                                            <div className="content-list">
                                                                <div className="contact-img">
                                                                    <a href={`/offices/${office.URL}/`}>
                                                                        <picture>
                                                                            <source media="(min-width:1200px)" srcSet={office.Image.url} />
                                                                            <source media="(min-width:768px)" srcSet={office.Image.url} />
                                                                            <img src={office.Image.url} alt={office.Image.alternativeText} />
                                                                        </picture>
                                                                    </a>
                                                                </div>

                                                                <div className="office-details-grid-boxes">
                                                                    <div className="title">
                                                                        <a href={`/offices/${office.URL}/`}>
                                                                            <h4>{HTMLReactParser(office.Title.replace(/\n/g, '<br />'))}</h4>
                                                                            <h4>{office.Sub_Title}</h4>
                                                                        </a>
                                                                    </div>

                                                                    <div className="address">
                                                                        {HTMLReactParser(office.Address.replace(/\n/g, '<br />'))}
                                                                    </div>

                                                                    <div className="phone">
                                                                        <a href={`tel:${office.Mobile}`}>
                                                                            Tel: {HTMLReactParser(office.Mobile.replace(/\n/g, '<br />'))}
                                                                        </a>
                                                                    </div>

                                                                    <div className="explore-buttons">
                                                                        <a href={`/offices/${office.URL}/`} className="btn btn-primary">
                                                                            Explore Now
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    </ScrollAnimation>
                                                </motion.div>
                                            </Col>
                                        )
                                    }
                                    )}

                                    {/* <Col className="col-6 block-2">
                                        <motion.div variants={contentVariants}>
                                            <ScrollAnimation animateIn='fadeInRight' animateOnce>
                                                <div className="contact-page-form">
                                                    <h2>Let's talk</h2>
                                                    <Form />
                                                </div>
                                            </ScrollAnimation>
                                        </motion.div>
                                    </Col> */}
                                </Row>
                            </Container>

                            {/* {data.glstrapi.siteConfig.Latitude ?
                            <Map lat={data.glstrapi.siteConfig.Latitude} log={data.glstrapi.siteConfig.Longtitude} Title={data.glstrapi.siteConfig.Address} /> : ''} */}

                        </motion.div>
                    )
                }}
            </InView>
            <Modal show={showForm} onHide={() => setShowForm(false)} className="enquire-form contact-modal" centered>
                <Modal.Header closeButton>
                    <Modal.Title className="px-0">Contact us</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ContactUsForm email={email} />
                </Modal.Body>
            </Modal>
        </div>
    )
}


export default OfficePage