import React from "react";
import { Link } from "@StarberryUtils"
import { useMatch } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col, Modal } from "react-bootstrap";
import Map from '../map/office-details-map'
import Form from '../forms/contact'
import AreaGuides from "../collections/AreaGuides/AreaGuides";
import HTMLReactParser from 'html-react-parser';
import ScrollAnimation from 'react-animate-on-scroll';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import ContactUsForm from "../forms/contact-us-form";

const containerVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.4,
            ease: [0.33, 1, 0.68, 1],
            duration: 0.4
        }
    }
}

const contentVariants = {
    hidden: {
        opacity: 0,
        y: 40
    },
    visible: {
        opacity: 1,
        y: 0,
        delay: 0.4,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 1
        }
    }
}

const LocalLiving = () => {
    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                localLivings(where: {Publish: true}, sort: "Sort") {
                    Name
                    Sort
                    URL
                    Image{
                        url
                    }
                }
            }
        }
    `);

    const page = data?.glstrapi?.offices;
    const [showForm, setShowForm] = React.useState(false);
    const [email, setEmail] = React.useState("");
    // const LocalLiving = useMatch("/about/local-living/:item/");
    // console.log('LocalLiving', LocalLiving)

    // console.log('off', office);

    return (
        <div>
            <InView threshold={0.2}>
                {({ ref, inView }) => {
                    return (
                        <motion.div className="contact-page-content office-page-content"
                            ref={ref}
                            initial="hidden"
                            animate={inView ? "visible" : "hidden"}
                            variants={containerVariants}
                        >
                            <Container>
                                <Row>
                                    {data.glstrapi.localLivings.map((living, a) => {
                                        return (
                                            <Col className="col-6 block-1 office-details-grid">
                                                <motion.div variants={contentVariants}>
                                                    <ScrollAnimation animateIn='fadeInLeft' animateOnce>
                                                        <>
                                                            <div className="content-list">
                                                                <div className="contact-img">
                                                                    <a href={`/about/local-living/${living.URL}/`}>
                                                                        <picture>
                                                                            <img src={living.Image.url} alt={living.Image.alternativeText} />
                                                                        </picture>
                                                                    </a>
                                                                </div>

                                                                <div className="office-details-grid-boxes">
                                                                    <div className="title">
                                                                        <h4>{living.Name}</h4>
                                                                    </div>
                                                                    <div className="explore-buttons">
                                                                        <a href={`/about/local-living/${living.URL}/`} className="btn btn-primary">
                                                                            Explore Now
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    </ScrollAnimation>
                                                </motion.div>
                                            </Col>
                                        )
                                    }
                                    )}

                                </Row>
                            </Container>
                        </motion.div>
                    )
                }}
            </InView>
        </div>
    )
}


export default LocalLiving