import React from "react";
import { Helmet } from "react-helmet";
import { Container } from "react-bootstrap";

const SocialWall = () => {
    return (
        <>
            <Helmet>
                <script src="https://apps.elfsight.com/p/platform.js" defer></script>
            </Helmet>
            <Container className="social-wall mb-5">
                <div class="elfsight-app-e1c85b6a-7ab9-401d-a496-8cfc86246758"></div>
            </Container>
        </>
    )
}

export default SocialWall;