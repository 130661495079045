import * as React from "react"
import { Container, Row, Modal } from "react-bootstrap"
import HTMLReactParser from 'html-react-parser';
import PlayVideo from "../../Play/custom-video";
import ScrollAnimation from 'react-animate-on-scroll'
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import { GetURL } from "../../common/site/functions";
import { Link } from "@StarberryUtils";
import ContactUsForm from "../../forms/contact-us-form";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";

const StaticContent = (props) => {
    const [showForm, setShowForm] = React.useState(false);
    const [email, setEmail] = React.useState("");
    if (typeof window !== "undefined") {
        var lastURLSegment = '';
        var pageURL = window.location.href.split("#")[0] ? window.location.href.split("#")[0] : '';
        var removeSlash = pageURL.replace(/^(.+?)\/*?$/, "$1");
        var lastURLSegment = removeSlash.substr(removeSlash.lastIndexOf('/') + 1);
        if(props.alias !=null) {
          lastURLSegment = props.alias
        }
    }

    let processedImages = JSON.stringify({});
    if (props.imagetransforms?.Banner_Image_Transforms) {
        processedImages = props.imagetransforms.Banner_Image_Transforms;
    }
    React.useEffect(() => {
        let el = document.getElementById("mortgage-mail");
        if(el != null) {
            el.addEventListener("click", () => {
                setShowForm(true);
                setEmail(el.innerText)
            })
        }
    });
    return (
        <>
            {props.class !== "Mortgage" && <div className={`static-page-head ${props.image ? 'pad-120' : ''}`}>

                {
                    lastURLSegment === "social" && (
                        <Breadcrumbs pageName = {props.pagename}/>
                    )
                }

                <div className="title">
                    {props.content ? HTMLReactParser(props.content.replace(/\n/g,'<br />')) : <h1>{props.pagename}</h1> }
                </div>
                
                
                {(props.label) &&
                    <div className="buttons area-guide-buttons">
                        <Link to="/book-a-valuation" className="btn btn-primary">
                            Market Appraisal
                        </Link>
                        <Link to={`/${GetURL(props.link._id)}`} className="btn btn-primary">
                            {props.label}
                        </Link>
                    </div>
                }
            </div>}
            <div className={`static-page-content ${props.class} ${props.class === "Mortgage" ? "mt-4" : ""}`}>
                <Container>
                    <Row>
                        {props.image &&
                            <div className="content">
                                <ScrollAnimation animateIn='fadeInUp' className="product-card-img static-banner img-zoom" animateOnce>

                                    {props.image &&
                                        <ImageTransform
                                            imagesources={props.image}
                                            renderer="srcSet" imagename={props.class === "Mortgage" ? "articles.Banner_Image.mortgage" : "articles.Banner_Image.details"}
                                            attr={{ alt: props.image_alt, class: '' }}
                                            imagetransformresult={processedImages}
                                            id={props.id}
                                        />
                                    }

                                    {props.video &&
                                        <div className="product-btn">
                                            <PlayVideo url={props.video}/>
                                        </div>
                                    }
                                </ScrollAnimation>
                            </div>
                        }
                        <div className="content-block">
                            {props.pagecontent && props.pagecontent.map((module, i) => {
                                return (
                                <>
                                    {module.Add_Static_Content &&
                                        <>
                                            {props.pagename === "Cookie Policy" ? 
                                                <div className="cookie-policy">
                                                    {HTMLReactParser(module.Add_Static_Content)}
                                                </div> : 
                                                HTMLReactParser(module.Add_Static_Content.replace(/\n/g,'<br />'))
                                            }
                                        </>
                                    }
                                </>
                                )
                            })}
                        </div>
                    </Row>
                </Container>
                <Modal show={showForm} onHide={() => setShowForm(false)} className="enquire-form contact-modal" centered>
                    <Modal.Header closeButton>
                        <Modal.Title className="px-0">Contact us</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ContactUsForm email={email}/>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

export default StaticContent