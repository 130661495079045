import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import LoadExternalScript from "../utils/load-external-script"
import LoadExternalStyle from "../utils/load-external-style"
import './CustomIframe.scss';

const CustomIframe = (props) => {
  const iframeUrl = props.iurl;
  const externalCssLink = props.externalCssLink;
  const externaljsLink = props.externalJsLink;
  return (
    <div className={"custom-iframe-block"}>
        <Container>
            <Row>
                {externalCssLink && <LoadExternalStyle
                    href={externalCssLink} /> }
                {externaljsLink && <LoadExternalScript
                    src={externaljsLink}
                /> }
                <div className="iframe-wrap">
                    {iframeUrl && <iframe className={"iframeBB custom-iframe"} src={iframeUrl}></iframe> }
                </div>
            </Row>
        </Container>
    </div>
  )
}

export default CustomIframe
