import * as React from "react"; 
import { Header } from "./Header/Header";
import { Footer } from "./Footer/Footer";
import BannerBlock1 from "./Home/Banner/Banner";
import AboutBlock from "./Home/About/About";
import OfficePage from "./OfficePage/OfficePage";
import LocalLiving from "./LocalLiving/LivingPage";
import Counter from "./Home/Counter/Counter";
import Reviews from "./Home/Review/Reviews";
import Gallery from "./Home/Gallery/Gallery";
import Service from "./Home/Service/Service";
import PropertiesList from "./Home/Properties/Properties";
import Static from "./Static/StaticBanner/StaticBanner";
import CTABlock from "./SearchResult/SellProduct/SellProduct";
import StaticContent from "./Static/StaticContent/StaticContent";
import IntroCard from "./Static/IntroCard/IntroCard";
import PropertyManagement from "./Static/PropertyManagement/PropertyManagement";
import Teams from "./collections/Teams/Teams";
import AreaGuides from "./collections/AreaGuides/AreaGuides";
import Movies from "./collections/Movies/Movies";
import ReviewsList from "./collections/Reviews/Reviews";
import ContactPage from "./ContactPage/ContactPage";
import ContactBanner from "./ContactPage/ContactBanner";
import OfficeBanner from "./OfficePage/OfficeBanner";
import SoldProperties from "./collections/SoldProperties/SoldProperty2";
import LetYourProperty from "./LetYouorProperty/LetYourProperty.";
import SocialWall from "./Social";
import handleViewPort from 'react-in-viewport';
import { LOCAL_LIVING } from "./common/site/constants";
import { lazyLoader } from "./common/site/functions";
import { isMobile, isSafari } from "react-device-detect";
import CustomIframe from './CustomIframe/CustomIframe'


const GalleryBlk = (props) => {
    const { inViewport, forwardedRef, enterCount } = props;
    return (
        <div ref={forwardedRef}>
            {(inViewport || enterCount > 0) && <Gallery />}
        </div>
    )
}

const PropertyBlk = (props) => {
    const { inViewport, forwardedRef, enterCount } = props;
    return (
        <div ref={forwardedRef}>
            {(inViewport || enterCount > 0) && <PropertiesList {...props} />}
        </div>
    )
}

const ReviewListBlk = (props) => {
    const { inViewport, forwardedRef, enterCount } = props;
    return (
        <div ref={forwardedRef}>
            {(inViewport || enterCount > 0) && <Reviews />}
        </div>
    )
}

const ServicesBlk = (props) => {
    const { inViewport, forwardedRef, enterCount } = props;
    return (
        <div ref={forwardedRef}>
            {(inViewport || enterCount > 0) && <Service {...props} />}
        </div>
    )
}

const GalleryBlock = handleViewPort(GalleryBlk, { config: { disconnectOnLeave: true } });

const PropertiesBlock = handleViewPort(PropertyBlk, { config: { disconnectOnLeave: true } });

const ReviewListBlock = handleViewPort(ReviewListBlk, { config: { disconnectOnLeave: true } });

const ServicesBlock = handleViewPort(ServicesBlk, { config: { disconnectOnLeave: true } });


const Modules = (props) => {
   
    const [loaded, setLoading] = React.useState(false);
    React.useEffect(() => {
        lazyLoader(window, () => {
            scriptInsert()
        })

    }, []);
    const scriptInsert = () => {
        if (!loaded) {
            setLoading(true)
        }
        // if (document.getElementById("bugherd") === null) {  
        //     var bugherdScript = document.createElement('script');
        //     bugherdScript.id = 'bugherd';
        //     bugherdScript.async = true;
        //     bugherdScript.type = 'text/javascript';
        //     bugherdScript.src = 'https://www.bugherd.com/sidebarv2.js?apikey=bttfufrozqzeblcodmx7zw';
        //     document.getElementsByTagName('body')[0].appendChild(bugherdScript);
        // }
    }


    return (
        <div className={`${props.page.Custom_CSS_Class} ${(isSafari && isMobile) ? "brw-saf" : ""}`}>
            <Header page={props.page.Pagename} />
            {props.page && props.page.Banner_Image && props.page.Header_Style === 'Full_Width_Banner' && props.page.Layout !== "Static_Page" && props.page.Layout !== "Contact" ?
                <BannerBlock1 
                   image={props.page.Banner_Image.url} image_alt={props.page.Banner_Image.alternativeText} imagetransforms={props.page.imagetransforms} video={props.page.Banner_Video} id={props.page.id} 
                   page={props.page}
                />
                : ''
            }

            {props.page && props.page.Header_Style === 'Content' ?
                <IntroCard content={props.page.Header_Content} {...props} /> : ''
            }

            {props.page && props.page.Banner_Image && props.page.Header_Style === 'Banner_With_Content' && props.page.Layout !== "Static_Page" && props.page.Layout !== "Contact" ?
                <Static image={props.page.Banner_Image.url} image_alt={props.page.Banner_Image.alternativeText} imagetransforms={props.page.imagetransforms} video={props.page.Banner_Video_URL} content={props.page.Header_Content} label={props.page.Header_CTA_Label} link={props.page.Header_CTA_Link} title={props.page.Pagename} contact={props.page.Contact_Team} id={props.page.id} />
                : ''
            }

            {props.page && props.page.Banner_Image && props.page.Layout === "Valuation_Page" &&
                <LetYourProperty image={props.page.Banner_Image.url} image_alt={props.page.Banner_Image.alternativeText} content={props.page.Header_Content} />
            }


            {props.page.Layout === "Static_Page" &&
                <StaticContent
                    image={props.page.Banner_Image ? props.page.Banner_Image.url : ''}
                    imagetransforms={props.page.imagetransforms}
                    image_alt={props.page.Banner_Image ? props.page.Banner_Image.alternativeText : ''}
                    video={props.page.Banner_Video_URL}
                    content={props.page.Header_Content}
                    pagename={props.page.Pagename}
                    pagecontent={props.modules}
                    class={props.page.Custom_CSS_Class}
                    label={props.page.Header_CTA_Label}
                    link={props.page.Header_CTA_Link}
                    id={props.page.id}
                />
            }

            {props.modules && props.modules.map((module, i) => {
                return (
                    <React.Fragment key={i}>
                        {module.Intro_Title && module.Intro_Content &&
                            <AboutBlock title={module.Intro_Title} content={module.Intro_Content} label={module.Intro_CTA_Label} link={module.Intro_CTA_Link?._id} />
                        }

                        {module.Stats &&
                            <Counter data={module.Stats} />
                        }

                        {module.Properties_Slider_Title &&
                            <PropertiesBlock
                                title={module.Properties_Slider_Title}
                                sub_title={module.Properties_Slider_Left_Side_Title}
                                place={module.Override_Default_Place}
                                url={props.uri}
                            />
                        }

                        {module.Select_Module === "Reviews_Slider" &&
                            <ReviewListBlock />
                        }

                        {module.Choose_Collection === 'Reviews' &&
                            <ReviewsList {...props} />
                        }

                        {module.Select_Module === "Instagram_Feed" &&
                            <GalleryBlock />
                        }
                        {module.__typename === "GLSTRAPI_ComponentModulesIframeBlock" &&                       
                            <CustomIframe iurl={module.URL} externalCssLink={module.external_css_url} externaljsLink={module.external_js_url} />
                        }

                        {module.id !="61b36d26f492406b0b185f62" && module.CTA_Block_Title &&
                            <CTABlock title={module.CTA_Block_Title} label={module.CTA_Block_Label} link={module.CTA_Block_Link} desc={module.CTA_Block_Description} class="val-cta-block" />
                        }

                        {module.Add_Service_Block &&
                            <ServicesBlock
                                data={module.Add_Service_Block}
                                left_title={module.Left_Side_Title}
                                class={props.page.Custom_CSS_Class}
                            />
                        }

                        {module.Choose_Collection === 'Teams' &&
                            <Teams />
                        } 

                        {module.Choose_Collection === 'All_Movies' &&
                            <Movies {...props} />
                        }

                        {module.Add_Block &&
                            <PropertyManagement data={module.Add_Block} />
                        }

                        {module.Choose_Collection === 'Sold_Properties' &&
                            <SoldProperties pagename={props.page.Pagename} />
                        }

                        {module.Choose_Collection === "Social" &&
                            <SocialWall />
                        }
                    </React.Fragment>
                )
            })
            }

            {props.page.Layout === "Contact" &&
                <div className="contact-form">
                    <ContactBanner
                        image={props.page.Banner_Image.url}
                        image_alt={props.page.Banner_Image.alternativeText}
                        video={props.page.Banner_Video_URL}
                        content={props.page.Header_Content}
                        label={props.page.Header_CTA_Label}
                        link={props.page.Header_CTA_Link}
                        pagename={props.page.Pagename}
                        page={props.page}
                        imagetransforms={props.page.imagetransforms}
                    />

                    {props.modules && props.modules.map((module, i) => {
                        return (
                            <React.Fragment key={i}>
                                {module.Choose_Collection === 'ContactPage' &&
                                    <ContactPage page={props.page} />
                                }
                            </React.Fragment>
                        )
                    })
                    }
                </div>
            }

            {props.page.Layout === "Offices" &&
                <div className="contact-form">

                    <OfficeBanner
                        video={props.page.Banner_Video_URL}
                        content={props.page.Header_Content}
                        label={props.page.Header_CTA_Label}
                        link={props.page.Header_CTA_Link}
                        pagename={props.page.Pagename}
                    />

                    {props.modules && props.modules.map((module, i) => {
                        return (
                            <React.Fragment key={i}>
                                {module.Choose_Collection === 'Offices' &&
                                    <OfficePage />
                                }
                                {module.Choose_Collection === 'Local_Living' && 
                                    <LocalLiving />
                                }
                                
                            </React.Fragment>
                        )
                    })
                    }

                </div>
            }


            <Footer search={props.page.Choose_Popular_Search} />
        </div>
    )

}
export default Modules