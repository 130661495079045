import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import HTMLReactParser from 'html-react-parser';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "@StarberryUtils"

import { GetURL } from "../../common/site/functions";

function PropertyManagement(props) {
    return (
        <div className="property-management">
        <Container>
            <Row>
            {props.data && props.data.map((module, i) => {
                let url = ''
                if(module.CTA_Link) {
                    url = GetURL(module.CTA_Link._id)
                }
                return (
                    <Col xl={6} className="mb-4">
                        <div className={`block ${i == 1 ? "property-bg-tag" : ""}`}>
                            <h2>{module.Title}</h2>
                            <h4>{module.Sub_Title}</h4>
                            {module.CTA_Label &&
                            <div className="buttons">
                                <Link to={`/${url}`} className="btn btn-primary">
                                    {module.CTA_Label}
                                </Link>
                            </div>
                            }
                            <div className="content pl-lg-3 pl-0">
                                {HTMLReactParser(module.Content.replace(/\n/g,'<br />'))}
                            </div>
                        </div>
                    </Col>
                )
            })}
            </Row>
        </Container>
        </div>
    )
}

export default PropertyManagement;