import React from "react"
import { Container } from "react-bootstrap";
import { Link } from "@StarberryUtils"
import './About.scss'
import ScrollAnimation from 'react-animate-on-scroll';
import HTMLReactParser from 'html-react-parser';
import { GetURL } from "../../common/site/functions";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const containerVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.4,
            ease: [0.33, 1, 0.68, 1],
            duration: 0.4
        }
    }
}

const titleVariants = {
    hidden: {
        opacity: 0,
        y: 40
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 1
        }
    }
}

const contentVariants = {
    hidden: {
        opacity: 0,
        y: 40
    },
    visible: {
        opacity: 1,
        y: 0,
        delay: 0.4,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 1
        }
    }
}

const AboutPage = (props) => {
    let url = ''
    if (props.link) {
        url = GetURL(props.link)
    }

    return (
        <section className="about-us">
            <Container>
                <div className="row">
                    {props.title ?
                        <div className="col-xl-4">
                            <motion.div variants={titleVariants}>
                                <ScrollAnimation animateIn='fadeInLeft' animateOnce>
                                    {HTMLReactParser(props.title.replace(/\n/g, '<br />'))}
                                </ScrollAnimation>
                            </motion.div>
                        </div> : ''
                    }
                    <div className="col-xl-8">
                        <motion.div variants={contentVariants}>
                            <ScrollAnimation animateIn='fadeInRight' animateOnce>
                                <div className="about-description">
                                    {HTMLReactParser(props.content.replace(/\n/g, '<br />'))}
                                    {props.label && url ?
                                        <div className="d-xl-block">
                                            <Link className="btn btn-primary " to={`/${url}`}>
                                                {props.label}
                                            </Link>
                                        </div> : ''
                                    }
                                </div>
                            </ScrollAnimation>
                        </motion.div>
                    </div>
                </div>
            </Container>
        </section>
    )
};
export default AboutPage