import * as React from "react"
import { graphql } from 'gatsby'
import  SEO from '../components/seo'
import Modules from '../components/modules'
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../scss/grunticon.scss";
import "../scss/bootstrap-overrides.scss";
import "../scss/global.scss";
import "../scss/custom.scss";
import "../scss/custom2.scss";

const DefaultTemplate = (props) => {
    const GQLPage = props.data.glstrapi?.article;
    const Page_Modules = props.data.glstrapi?.article?.Modules;
    return (
        <div className={`wrapper ${GQLPage.Pagename == 'Home' ? '' : 'wrapper-static'}`}>
            <SEO
                title={props.pageContext.meta_title ? props.pageContext.meta_title : GQLPage.Meta_Title}
                description={props.pageContext.meta_desc ? props.pageContext.meta_desc : GQLPage.Meta_Description}
            />
            <Modules page={GQLPage} modules={Page_Modules} {...props}/>
        </div>
    )
}

export default DefaultTemplate

export const pageQuery = graphql`
  query defaultQuery($id: ID!) {
	  glstrapi {
	  	article(id: $id, publicationState: LIVE) {
	      Modules {
	        ... on GLSTRAPI_ComponentModulesIntro {
	          Intro_CTA_Label
	          Intro_Content
	          Intro_Title
	          Intro_CTA_Link {
	            _id
	          }
	        }
	        ... on GLSTRAPI_ComponentModulesStatistics {
	          Stats {
	            Count
	            Stats_Content
	          }
	          _id
	        }
		    ... on GLSTRAPI_ComponentModulesServices {
	          id
	          Left_Side_Title
	          Add_Service_Block {
	          	Service_Title
	            Service_CTA_Label
	            Service_Content
	            Service_CTA_Link {
	              _id
	            }
	            Service_Image {
	              alternativeText
	              url
                  url_sharp {
                    childImageSharp {
                        fluid(quality: 100, maxWidth: 1200, maxHeight:700) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                     }
                  }
	            }

	          }
	        }
	        ... on GLSTRAPI_ComponentModulesPropertiesSlider {
	          id
	          Override_Default_Place
	          Properties_Slider_Left_Side_Title
	          Properties_Slider_Title
	        }
	        ... on GLSTRAPI_ComponentModulesModules {
	          id
	          Select_Module
	        }
			... on GLSTRAPI_ComponentModulesIframeBlock {
				__typename
				id
				URL
				external_css_url
				external_js_url
			}
	        ... on GLSTRAPI_ComponentModulesBookValuationCtaBlock {
	          id
	          CTA_Block_Label
	          CTA_Block_Title
			  CTA_Block_Description
	          CTA_Block_Link {
	            _id
				URL
	          }
	        }
	        ... on GLSTRAPI_ComponentModulesContent {
	          Add_Static_Content
	          id
	        }
            ... on GLSTRAPI_ComponentModulesCollection {
                id
                Choose_Collection
            }
	        ... on GLSTRAPI_ComponentModulesPropertyManagementBlocks {
	          id
	          Add_Block {
	            CTA_Label
	            Content
	            Sub_Title
	            Title
	            CTA_Link {
	              _id
	            }
	          }
	        }
	      }
          id
	      Pagename
	      Meta_Description
	      Meta_Title
	      Layout
	      Header_Style
	      Header_CTA_Label
	      Custom_CSS_Class
	      Banner_Video_URL
          Banner_Video {
            url
          }
	      Header_CTA_Link {
	        _id
	      }
	      Banner_Image {
	        url
	        alternativeText
	      }
	      Header_Content
          Contact_Team {
            Tile_Image {
              alternativeText
              url
            }
            Name
            Phone
            Email
          }
          imagetransforms
          Choose_Popular_Search
          Show_Holidays_Banner
	  	}
	  }
  }
`