import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import './SoldProperty.scss';
import '../../ProductDetails/MenuList/MenuList.scss';
import { SoldProperties } from '../../../queries/common-use-query';
import { RefinementList, InstantSearch, Configure, connectInfiniteHits, connectStateResults } from 'react-instantsearch-dom';
import Loader from '../../loader';
import algoliasearch from 'algoliasearch/lite';
import { ShowProcessedImage } from '../../common/ggfx-client/module/components/show-image';
import imageConfig from "../../../../static/images/config.json";
import moment from 'moment';
const client = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_API_KEY);
const index_name = process.env.GATSBY_ALGOLIA_INDEX_NAME+"_recent";
const Categories = (props) => {
    const addresses = props.addresses;
    return (
        <div className="d-flex flex-row justify-content-between button-text-boxes">
            <div class="form-select-boxes">
                <select className="form-control form-control-arrow" onChange={(e) => props.setCategory(e.target.value)} >
                    <option value="all">All {props.type}</option>
                    {addresses.length > 0 && addresses.map((each, i) =>
                        <option key={i} value={each}>{each}</option>
                    )}
                </select>
            </div>
            {/* <div className="search-area-control">
                <input
                    className="form-control"
                    value={props.query}
                    onChange={(e) => props.handleChange(e.target.value)}
                    type="text"
                    placeholder="Search Area"
                />
                <i className="icon-search" />
            </div> */}
        </div>
    )
}
const PropertyCard = (props) => {
    const { hits, hasPrevious, refinePrevious, hasMore, refineNext, location, category } = props;
    let totalHits = category == "all" ? hits : hits.filter(c => c.area === category);
    if (props.query !== "") {
        totalHits = totalHits.filter(c => c.area.toLowerCase().includes(props.query.toLowerCase()) || c.display_address.toLowerCase().includes(props.query.toLowerCase()))
    }
    return (
        <React.Fragment>
            {totalHits.filter(c => c.images.length > 0).map((each, i) =>
                <div className={`img${i % 9} image-wrapper`} key={i}>
                    {each.images &&
                        <div className={`gallery-image sold-link bg-img w-100 position-relative`}>
                            <ShowProcessedImage
                                images={each.images[0]}
                                transforms={imageConfig.property.images.searchResults.sizes}
                            />
                            <div className="w-100 h-100 backdrop-content d-flex flex-column justify-content-end text-left">
                                <h1>{each.display_address} </h1>
                                {(each.time_amended && props.type == "Sold") && <button>{moment(each.time_amended).format("YYYY")}</button>}
                            </div>
                        </div>
                    }
                </div>
            )}
        </React.Fragment>
    )
}
const CustomInfiniteHits = connectInfiniteHits(PropertyCard)
const SoldProperty = (props) => {
    const [category, setCategory] = React.useState(null);
    const [query, setQuery] = React.useState("");
    const [loader, showLoader] = React.useState(false);
    const [addresses, setAddresses] = React.useState([]);
    let type = props.pagename === "Let Properties" ? "Let" : "Sold";
    const { data, loading, error } = SoldProperties(type);
    let omittedAreas = ["Slough", "Bucks"];
    useEffect(() => {
        if (data && data.properties) {
            const addresses = [...new Set(data.properties.filter(c => !omittedAreas.includes(c.area) && c.images.length > 0).map(property => property.area))];
            setAddresses(addresses);
            setCategory("all")
        }
    }, [data]);
    return (
        <div className="sold-prop-header">
            <div className="container-fluid">
                <Row className="justify-content-center menu-list row align-items-center">
                    <Col xs={12} md={10} lg={10} xl={10} className="sold-prop-list">
                        <Categories
                            addresses={addresses}
                            setCategory={setCategory}
                            category={category}
                            handleChange={(val) => setQuery(val)}
                            type={type}
                            query={query}
                        />
                    </Col>
                </Row>
            </div>
            <div className="gallery-wrapper gallery-sold-wrapper px-xl-4 px-md-3 px-1">
                {(loading || loader) && <div className="d-flex justify-content-center w-100">
                    <Loader />
                </div>}
                <InstantSearch
                    indexName={index_name}
                    searchClient={client}
                    hits
                    showLoadingIndicator
                >
                    <div className="d-none">
                        <RefinementList
                            attribute="status"
                            defaultRefinement={type}
                        />
                        <Configure
                            hitsPerPage={1000}
                        />
                    </div>
                    <CustomInfiniteHits type={type} category={category} query={query} />
                </InstantSearch>
            </div>
        </div>
    )
}
export default SoldProperty;