import HTMLReactParser from 'html-react-parser';
import React, { useEffect } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Row, Col } from 'react-bootstrap';
import ValuationFormPage from '../forms/valuation-form';
import PlayVideo from '../Play/custom-video';
import "../Static/StaticBanner/StaticBanner.scss";

const LetYourProperty = (props) => {
    return (
        <section className="property-card static-banner landing-page-static-banner valuation-form">
            <ScrollAnimation animateIn='fadeInRight' className="product-card-img img-zoom" animateOnce>
                <picture>
                    <source media="(min-width:1200px)" srcSet={props.image} />
                    <source media="(min-width:768px)" srcSet={props.image} />
                    <img src={props.image} alt={props.image_alt} />
                </picture>

                {props.video &&
                    <div className="product-btn">
                        <PlayVideo url={props.video}/>
                    </div>
                }
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInLeft' className="product-card-details" animateOnce>
                {HTMLReactParser(props.content.replace(/\n/g,'<br />'))}
                <ValuationFormPage/>
            </ScrollAnimation>
        </section>
    )
}

export default LetYourProperty;
{/* <section>
    <ScrollAnimation animateIn='fadeInUp'>
        <div className="container">
            <Row className="justify-content-center row align-items-center">
                <Col xl={6} md={12}>
                    {HTMLReactParser(props.content.replace(/\n/g,'<br />'))}
                </Col>
                <Col xl={6} md={12}>
                    <ScrollAnimation animateIn='fadeInRight' className="product-card-img img-zoom" animateOnce>
                        <picture>
                            <source media="(min-width:1200px)" srcSet={props.image} />
                            <source media="(min-width:768px)" srcSet={props.image} />
                            <img src={props.image} alt={props.image_alt} />
                        </picture>

                        {props.video &&
                            <div className="product-btn">
                                <PlayVideo url={props.video}/>
                            </div>
                        }
                        </ScrollAnimation>
                    </Col>
            </Row>
        </div>
    </ScrollAnimation>
</section> */}