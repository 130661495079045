import React from "react";
import HTMLReactParser from 'html-react-parser';
import { GetURL } from "../common/site/functions";
import { Modal } from "react-bootstrap";
import ConsulationForm from "../../components/forms/consultation-form";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import ScrollAnimation from 'react-animate-on-scroll';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import { graphql, useStaticQuery } from "gatsby";
import { useLocation } from "@reach/router";

const containerVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.4,
            ease: [0.33, 1, 0.68, 1],
            duration: 0.4
        }
    }
}

const contentVariants = {
    hidden: {
        opacity: 0,
        y: 40
    },
    visible: {
        opacity: 1,
        y: 0,
        delay: 0.4,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 1
        }
    }
}

const ContactBanner = (props) => {
    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                offices {
                    Email
                    Title
                    URL
                }
            }
        }
    `);
    const location = useLocation();

    const offices = data.glstrapi.offices;
    const current_office = offices.some(c => location.pathname.includes(c.URL)) ? offices.filter(c => location.pathname.includes(c.URL)) : null;
    const [showForm, setShowForm] = React.useState(false);
    const [email, setEmail] = React.useState("");
    React.useEffect(() => {
        if (current_office != null) {
            setEmail(current_office[0]?.Email)
        }
    }, [])

    let url = ''
    if (props.link) {
        url = GetURL(props.link._id)
    }
    let processedImages = JSON.stringify({});
    if (props.imagetransforms?.Banner_Image_Transforms) {
        processedImages = props.imagetransforms.Banner_Image_Transforms;
    }
    return (
        <InView threshold={0.2}>
            {({ ref, inView }) => {
                return (
                    <motion.section className="banner contact-page-banner"
                        ref={ref}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={containerVariants}
                    >
                        <div className="bg-img" style={{ backgroundImage: `url(${props.image})` }}>
                            {props.image &&
                                <ImageTransform
                                    imagesources={props.image}
                                    renderer="srcSet" imagename="articles.Banner_Image.details"
                                    attr={{ alt: props.image_alt, class: '' }}
                                    imagetransformresult={processedImages}
                                    id={props.id}
                                />
                            }
                            <div className="content">
                                <h5>{props.pagename}</h5>
                                {current_office == null && HTMLReactParser(props.content.replace(/\n/g, '<br />'))}
                                {current_office != null && <h1>Contact Estate Agents in {current_office[0]?.Title}</h1>}
                                {props.label &&
                                    <a onClick={() => setShowForm(true)} className="btn btn-primary">
                                        {props.label}
                                    </a>
                                }
                            </div>
                        </div>
                        <Modal show={showForm} onHide={() => setShowForm(false)} className="enquire-form contact-modal" centered>
                            <Modal.Header closeButton>
                                <Modal.Title className="px-0">Book a Consultation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <ConsulationForm email={email} />
                            </Modal.Body>
                        </Modal>
                    </motion.section>
                )
            }}
        </InView>
    )
}

export default ContactBanner