import React from "react"
import './Counter.scss'
import ScrollAnimation from 'react-animate-on-scroll'
import { Container } from "react-bootstrap"
import HTMLReactParser from 'html-react-parser';

import { inViewOptions } from '../../utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import CountUp from "react-countup";

const containerVariants = {
    hidden: {
        opacity: 0,
        y: 60,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 0.4
        }
    }
}

const Counter = (props) => {
        return (
        <InView {...inViewOptions}>
            {({ ref, inView }) => (
                <motion.section className="counter"
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={containerVariants}
                >
                    <Container>
                        <div className="counter-wrapper">
                            <div className="row">
                                {props.data && props.data.map((count, i) => {
                                    const prefix = count.Count.includes("£") ? "£" : "";
                                    const suffix = count.Count.endsWith("m+") ? "m+" : count.Count.endsWith("+") ? "+" : count.Count.endsWith("%") ? "%" : "/5";
                                    const number = count.Count.endsWith('/5') ? parseFloat(count.Count.replace("/5", "")) : parseInt(count.Count.match(/\d/g).join(""))
                                    return (
                                        <div className="col-6 col-xl-3" key={i}>
                                            <ScrollAnimation animateIn='fadeInUp' animateOnce>
                                                <div className="total-record">
                                                    <span className="total-count">
                                                        <CountUp
                                                            prefix={prefix}
                                                            end={number}
                                                            suffix={suffix}
                                                            duration={5}
                                                            decimals={count.Count.endsWith('/5') ? 1 : 0}
                                                        />
                                                    </span>

                                                    <p className="sm-content">
                                                        {HTMLReactParser(count.Stats_Content.replace(/\n/g, '<br />'))}
                                                    </p>
                                                </div>
                                            </ScrollAnimation>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </Container>
                </motion.section>
            )}
        </InView>
    )
};

export default Counter