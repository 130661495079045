import { useEffect } from 'react';

/**
 * LoadExternalStyle Component
 *
 * @param {string} href - The URL of the external CSS stylesheet to load.
 * @param {function} onLoad - Optional callback function to be executed when the stylesheet is successfully loaded.
 */
export default function LoadExternalStyle(props) {
  const { href, onLoad } = props;
  useEffect(() => {
    // Check if a valid href is provided
    if (!href) {
      return;
    }

    // Create a <link> element to load the stylesheet
    const link = document.createElement('link');
    link.rel = 'stylesheet'; // Set the link's relationship to "stylesheet"
    link.href = href; // Set the href attribute to the provided URL
    
    // Define an onload event handler to execute when the stylesheet is loaded
    link.onload = () => {
      // If an onLoad callback is provided, execute it
      if (typeof onLoad === 'function') {
        onLoad();
      }
    };

    // Append the <link> element to the <head> of the document
    document.head.appendChild(link);

    // Define a cleanup function to remove the <link> element when the component unmounts
    return () => {
      document.head.removeChild(link);
    };
  }, [href, onLoad]);

  // This component does not render any content to the DOM, so it returns null
  return null;
}