import React from "react";
import { Link } from "@StarberryUtils"
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col, Modal } from "react-bootstrap";
import Map from '../map/office-details-map'
import Form from '../forms/contact'
import HTMLReactParser from 'html-react-parser';
import ScrollAnimation from 'react-animate-on-scroll';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import ContactUsForm from "../forms/contact-us-form";
import { useLocation } from "@reach/router";

const containerVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.4,
            ease: [0.33, 1, 0.68, 1],
            duration: 0.4
        }
    }
}

const contentVariants = {
    hidden: {
        opacity: 0,
        y: 40
    },
    visible: {
        opacity: 1,
        y: 0,
        delay: 0.4,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 1
        }
    }
}

const ContactPage = () => {
    const data = useStaticQuery(graphql`
        query {
            glstrapi {
			    siteConfig {
                    Address
                    Contact_Page_Intro
                    Office_Timing
                    Latitude
                    Longtitude
                    Contact_Page_Speak_With {
                        Name
                        Tile_Image {
                        url
                        alternativeText
                        }
                        Phone
                        Email
                        Designation
                    }
                    Email {
                        Email
                    }
                    Phone {
                        Phone_Number
                    }
			    }
                offices {
                    Email
                    Address
                    Title
                    URL
                    Mobile
                    Latitude
                    Longitude
                    Office_Hours {
                        Office_Timings {
                            Timing
                        }
                    }
                    Speak_With_Team {
                        Name
                        Tile_Image {
                        url
                        alternativeText
                        }
                        Phone
                        Email
                        Designation
                    }
                }
            }
        }
    `);

    const page = data.glstrapi.siteConfig;
    const offices = data.glstrapi.offices;
    const [showForm, setShowForm] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const [office, setOffice] = React.useState(null);
    const [team, setTeam] = React.useState(null);
    const location = useLocation();
    const current_office = offices.some(c => location.pathname.includes(c.URL)) ? offices.filter(c => location.pathname.includes(c.URL)) : null;
    React.useEffect(() => {
        if (current_office != null) {
            setEmail(current_office[0]?.Email)
            setOffice(current_office[0])
            setTeam(current_office[0]?.Speak_With_Team)
        }
    }, [])


    // console.log ("xxxxxxxx", props)

    return (
        <div>
            <InView threshold={0.2}>
                {({ ref, inView }) => {
                    return (
                        <motion.div className="contact-page-content"
                            ref={ref}
                            initial="hidden"
                            animate={inView ? "visible" : "hidden"}
                            variants={containerVariants}
                        >
                            <Container>
                                <Row>
                                    <Col className="col-6 block-1">
                                        <motion.div variants={contentVariants}>
                                            <ScrollAnimation animateIn='fadeInLeft' animateOnce>
                                                {page.Contact_Page_Intro ? HTMLReactParser(page.Contact_Page_Intro.replace(/\n/g, '<br />')) : ''}

                                                {(page.Phone && current_office == null) &&
                                                    <div className="phone">
                                                        <i className="icon-phone">
                                                        </i>
                                                        {page.Phone.map((phone, i) => {
                                                            return (
                                                                <a href={`tel:${phone.Phone_Number}`}>
                                                                    {phone.Phone_Number}
                                                                </a>
                                                            )
                                                        })}
                                                    </div>
                                                }
                                                {(current_office != null) &&
                                                    <div className="phone">
                                                        <i className="icon-phone">
                                                        </i>
                                                        {current_office.map((off, i) => {
                                                            return (
                                                                <a href={`tel:${off.Mobile}`}>
                                                                    {off.Mobile}
                                                                </a>
                                                            )
                                                        })}
                                                    </div>
                                                }
                                                {(page.Email && current_office == null) &&
                                                    <div className="email">
                                                        <i className="icon-mail">
                                                        </i>
                                                        {page.Email.map((email, i) => {
                                                            return (
                                                                <a href="javascript:;" onClick={() => { setEmail(email.Email); setShowForm(true); }} >
                                                                    {email.Email}
                                                                </a>
                                                            )
                                                        })}
                                                    </div>
                                                }
                                                {(current_office != null) &&
                                                    <div className="email">
                                                        <i className="icon-mail">
                                                        </i>
                                                        {current_office.map((off, i) => {
                                                            return (
                                                                <a href="javascript:;" onClick={() => { setEmail(off.Email); setShowForm(true); }} >
                                                                    {off.Email}
                                                                </a>
                                                            )
                                                        })}
                                                    </div>
                                                }
                                                {(page.Address && current_office == null) &&
                                                    <div className="address">
                                                        <i className="icon-address">
                                                        </i>
                                                        {HTMLReactParser(page.Address.replace(/\n/g, '<br />'))}
                                                    </div>
                                                }
                                                {(current_office != null) &&
                                                    <div className="address">
                                                        <i className="icon-address">
                                                        </i>
                                                        {HTMLReactParser(current_office[0]?.Address.replace(/\n/g, '<br />'))}
                                                    </div>
                                                }
                                                {(page.Office_Timing && current_office == null) &&
                                                    <div className="timing">
                                                        <i className="icon-time">
                                                        </i>
                                                        {HTMLReactParser(page.Office_Timing.replace(/\n/g, '<br />'))}
                                                    </div>
                                                }
                                                {current_office != null &&
                                                    <div className="timing">
                                                        <i className="icon-time">
                                                        </i>
                                                        {current_office[0]?.Office_Hours?.Office_Timings.map(c => <p>{c.Timing}</p>)}
                                                    </div>
                                                }

                                                {(page.Contact_Page_Speak_With && current_office == null) &&
                                                    <div className="speak">
                                                        <h3>Speak with the team.</h3>
                                                        <Row>
                                                            {page.Contact_Page_Speak_With.map((person, i) => {
                                                                return (
                                                                    <Col className="col-6">
                                                                        <div className="person">
                                                                            <div className="image">
                                                                                <img src={person.Tile_Image.url} alt={person.Tile_Image.alternativeText} />
                                                                            </div>
                                                                            <div className="details">
                                                                                <h4>{person.Name}</h4>
                                                                                <h5>{person.Designation}</h5>
                                                                                {person.Phone && <p><a href={`tel:${person.Phone}`}>{person.Phone}</a></p>}
                                                                                {person.Email &&
                                                                                    <p>
                                                                                        <a href="javascript:;" onClick={() => { setEmail(person.Email); setShowForm(true); }} className="email">Email {person.Name.split(" ")[0]}</a>
                                                                                    </p>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                )
                                                            })}
                                                        </Row>
                                                    </div>
                                                }
                                                {(current_office != null && team != null) &&
                                                    <div className="speak">
                                                        <h3>Speak with the team.</h3>
                                                        <Row>
                                                            <Col className="col-8">
                                                                <div className="person">
                                                                    <div className="image">
                                                                        <img src={team.Tile_Image.url} alt={team.Tile_Image.alternativeText} />
                                                                    </div>
                                                                    <div className="details">
                                                                        <h4>{team.Name}</h4>
                                                                        <h5>{team.Designation}</h5>
                                                                        {team.Phone && <p><a href={`tel:${team.Phone}`}>{team.Phone}</a></p>}
                                                                        {team.Email &&
                                                                            <p>
                                                                                <a href="javascript:;" onClick={() => { setEmail(team.Email); setShowForm(true); }} className="email">Email {team.Name.split(" ")[0]}</a>
                                                                            </p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                }
                                            </ScrollAnimation>
                                        </motion.div>
                                    </Col>
                                    <Col className="col-6 block-2">
                                        <motion.div variants={contentVariants}>
                                            <ScrollAnimation animateIn='fadeInRight' animateOnce>
                                                <div className="contact-page-form">
                                                    <h2>Let's talk</h2>
                                                    <Form email={email} office={office} />
                                                </div>
                                            </ScrollAnimation>
                                        </motion.div>
                                    </Col>
                                </Row>
                            </Container>
                            {/* {data.glstrapi.offices.length > 0 ?
                                <Map 
                                    lat={data.glstrapi.offices.filter(c => location.pathname.includes(c.URL))[0]?.Latitude} 
                                    log={data.glstrapi.offices.filter(c => location.pathname.includes(c.URL))[0]?.Longtitude} 
                                    Title={data.glstrapi.offices.filter(c => location.pathname.includes(c.URL))[0]?.Address} 
                                /> : ''} */}

                            {(data.glstrapi.offices.length > 0 && (location.pathname.includes("windsor") || location.pathname.includes("beaconsfield") )) ?
                                <Map
                                    lat={data.glstrapi.offices.filter(c => location.pathname.includes(c.URL))[0]?.Latitude}
                                    log={data.glstrapi.offices.filter(c => location.pathname.includes(c.URL))[0]?.Longitude}
                                    Title={data.glstrapi.offices.filter(c => location.pathname.includes(c.URL))[0]?.Address}
                                /> : ''}
                                {(!location.pathname.includes("windsor") && !location.pathname.includes("beaconsfield") && data.glstrapi.siteConfig.Latitude) &&
                                    <Map lat={data.glstrapi.siteConfig.Latitude} log={data.glstrapi.siteConfig.Longtitude} Title={data.glstrapi.siteConfig.Address} />
                                }
                        </motion.div>
                    )
                }}
            </InView>
            <Modal show={showForm} onHide={() => setShowForm(false)} className="enquire-form contact-modal" centered>
                <Modal.Header closeButton>
                    <Modal.Title className="px-0">Contact us</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ContactUsForm email={email} office={office} />
                </Modal.Body>
            </Modal>
        </div>
    )
}


export default ContactPage