import React, { useState, useEffect } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Row, Col } from 'react-bootstrap';
// import { graphql, useStaticQuery } from 'gatsby';
import './Movies.scss';
import Loader from '../../loader';
import ImageTransform from '../../common/ggfx-client/module/components/image-transform';
import YouTube from 'react-youtube';
import closeIcon from "../../../images/close.png"
import { graphql, useStaticQuery } from 'gatsby';
import axios from 'axios';


const videoRef = React.createRef(null);
function Movies(props) {
    const [category, setCategory] = React.useState(null);
    const [areas, setAreas] = React.useState([])
    const [loader, showLoader] = React.useState(true);
    const [showTour, setVirtualTour] = React.useState(false);
    const [moviesList, setProperties] = React.useState([]);
    const [virtualTour, setVirtualTourUrl] = React.useState("");
    const [query, setQuery] = React.useState("");
    const [data, setData] = useState(false);
    const [fetchdata, setFetchData] = useState(false);

    let url = process.env.GATSBY_STRAPI_SRC + "/properties?virtual_tour_null=false&_sort=time_amended:desc&publish=true"; // TODO: base URL supposed to be from .env
    const getitems = async url => {
        try {
            const { data } = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
            }})
            setData(data);
            setFetchData(true);
            const addresses = [...new Set(data.map(property => property.area))];
            setAreas(addresses);            
            showLoader(false);
            if(category == "all" || category == null){
                setProperties(data);
            }
            showLoader(false);
            if(query !== "") {
                moviesList = moviesList.filter(c => c.area.toLowerCase().includes(query.toLowerCase()) || c.display_address.toLowerCase().includes(query.toLowerCase()))
            }
        } catch (error) {
          console.error(error)  
        }
    }
    useEffect(() => {
        getitems(url);
    },[]) 

    useEffect(() => {
        setProperties([]);
        setTimeout(() => {
        if(data){
            const filteredProperties = category == "all" ? data : data?.filter(c => c?.area.includes(category));
            setProperties(filteredProperties);
        }
        }, 0);
    },[category]);
    

    const getVideoId = (url) => {
        if(url.split("v=").length > 1) {
            var video_id = url.split('v=')[1];
            var ampersandPosition = video_id.indexOf('&');
            if(ampersandPosition != -1) {
                video_id = video_id.substring(0, ampersandPosition);
            }
            return video_id
        } else {
            return url.split("youtu.be/")[1]
        }
    }
    const closeVideo = () => {
        setVirtualTourUrl("");
        setVirtualTour(false);
    }
    
    return (
        <section className="movies px-xl-4 px-md-3 px-1">
        <ScrollAnimation animateIn='fadeInUp'>
            <Row className="justify-content-center">
                <Col xs={12} md={10} lg={10} xl={10} className="overflow-auto categoty-list-container">
                    <div className="d-flex flex-row justify-content-between button-text-boxes">
                        {/* <a className={`category ${category == "all" ? "active" : ""}`} href="#all" onClick={() => setCategory("all")}>All Movies</a>
                        {(data && areas.length > 0) && areas.map((each, i) =>
                            // .map(c => c.includes(each.Name.toLowerCase())) &&
                            <a key={i} className={`category ${category == `${each.replace(/-/g, ' ')}` ? "active" : ""}`} href={`#${each.replace(/-/g, '-')}`} onClick={() => setCategory(`${each}`)}>{each}</a>
                        )} */}
                        <div class="form-select-boxes">
                            <select className="form-control form-control-arrow" onChange={(e) => setCategory(`${e.target.value}`)}>
                                <option value="all">All {props.type}</option>
                                {(data && areas.length > 0) && areas.map((each, i) =>
                                    <option key={i} value={each}>{each}</option>
                                )}
                            </select>
                        </div>
                        {/* <div className="search-area-control">
                            <input
                                className="form-control"
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                type="text"
                                placeholder="Search Area"
                            />
                            <i className="icon-search" />
                        </div> */}
                    </div>
                </Col>
            </Row>
            {loader &&
                <div className="d-flex justify-content-center w-100">
                    <Loader/>
                </div>
            }
            <div className="mt-4">
                {fetchdata &&                
                <div className="gallery-wrapper">
                    {moviesList?.map((each, i) => {
                        let processedImages = JSON.stringify({});
                        if (each?.imagetransforms?.images_Transforms) {
                            processedImages = each.imagetransforms.images_Transforms;
                        }
                        return (
                            <div className={`img${i%9} image-wrapper`} key={i}>
                                <div className={`gallery-image bg-img w-100 position-relative`}>
                                    {each.images.length > 0 &&
                                        <ImageTransform
                                            imagename="property.images.featured"
                                            attr={{ alt: '', class:'' }}
                                            imagesources={each.images[0].url}
                                            renderer="srcSet"
                                            imagetransformresult={processedImages}
                                            id={each._id}
                                        />
                                    }
                                    <div className="w-100 h-100 backdrop-content position-absolute d-flex flex-column justify-content-end text-left">
                                        <h1>{each.display_address}</h1>
                                        <p>{each.price_qualifier} <span>£ {each.price.toLocaleString()}</span></p>
                                    </div>
                                    {each.virtual_tour &&
                                        <div className="product-btn">
                                            <strong className="video-btn" onClick={async e => {
                                                    await setVirtualTourUrl(each.virtual_tour);
                                                    await setVirtualTour(true);
                                                    // playVideo()
                                                }} >
                                                <i className="icon-video"/>
                                            </strong>
                                        </div>
                                    }
                                    {(virtualTour === each.virtual_tour && showTour) && <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
                                        <YouTube
                                            className="video-inline"
                                            videoId={getVideoId(each.virtual_tour)}
                                            opts={{
                                                height: "100%",
                                                width: "100%",
                                                playerVars: {
                                                    autoplay: 1,
                                                    disablekb: 1,
                                                    showinfo: 0,
                                                    modestbranding: 0,
                                                    enablejsapi: 1
                                                }
                                            }}
                                            onEnd={closeVideo}
                                            onError={(err) => {
                                                console.log(err)
                                                closeVideo()
                                            }}
                                        />
                                        <div className="btn btn-light close-icon" onClick={closeVideo}>
                                            <img src={closeIcon} alt="close-icon"/>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                            )
                        }
                    )}
                </div>
            }   
            </div>
        </ScrollAnimation>
        {/* {virtualTour !== "" && <PlayVideo
            isOpen={showTour}
            isCloseFunction={() => setVirtualTour(false)}
            // videoId={virtualTour.split("v=").length > 1 ? virtualTour.split("v=")[1] : virtualTour.split("youtu.be/")[1]}
            videoId={getVideoId(virtualTour)}
            isAutoPlay={1}
        />} */}
        {/* <div ref={videoRef}>
            {(virtualTour && showTour) &&
                <div style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, zIndex: 9999 }}>
                    <YouTube
                        className="video-inline"
                        videoId={getVideoId(virtualTour)}
                        opts={{
                            height: "100%",
                            width: "100%",
                            playerVars: {
                                autoplay: 1,
                                disablekb: 1
                            }
                        }}
                        onEnd={closeVideo}
                        onError={(err) => {
                            console.log(err)
                            closeVideo()
                        }}
                    />
                    <div className="btn btn-light close-icon" onClick={closeVideo}>
                        <img src={closeIcon} alt="close-icon"/>
                    </div>
                </div>
            }
        </div> */}
        </section>
    );
}
export default Movies;