import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import axios from "axios";
import './Reviews.scss';
import ReactReadMoreReadLess from "react-read-more-read-less";
import ScrollAnimation from 'react-animate-on-scroll';
import moment from "moment/src/moment";

function Reviews(props) {
    const [reviewList, setCustomerReview] = useState([]);
    const [allReviewList, setReviews] = useState([]);
    let [isLess, setLess] = useState(false);

    const wordToNumber = {'ONE': 1, 'TWO': 2, 'THREE': 3, 'FOUR': 4, 'FIVE': 5};

    let intialNumberOfReview = 10;
    const strapiconfig = {
        headers: {
            Authorization:
                `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
        },
    }

    const getitems = async url => {
        try {
            const { data } = await axios.get(url,strapiconfig)// could be from env files 
            const reviews = data.filter(rev => rev.comment != null && (rev.starRating === "FOUR" || rev.starRating === "FIVE"));
            setReviews(reviews);
            setCustomerReview(reviews.slice(0, intialNumberOfReview));
        } catch (error) {
            // cache it if fail/error;
            console.error(error)
        }
    }

    const loadMore = () => {
        let primeryLength = reviewList.length;
        let newList = allReviewList.slice(
            0,
            primeryLength + intialNumberOfReview
        );
        setCustomerReview(newList);
        if (newList.length === allReviewList.length) {
            setLess(true);
        } else {
            setLess(false);
        }
    };

    const starRating = (rating)=>{
        var star = [];
        for (let i = 0; i < rating; i++) {
            star.push(<i className="star-icon"></i>);  
        }
        return star;
    }

    useEffect(() => {
        let url = `${process.env.GATSBY_STRAPI_SRC}/stb-google-reviews/google-review-reviews`
        getitems(url);
        console.log("google-reviews",url);
    }, []);

    return (
        <Container className="reviews-list">
            <Row className="justify-content-start pb-4">
                {reviewList.map((review, i) => 
                    <Col lg={6} xs={12} key={i} className="h-100">
                        <ScrollAnimation animateIn={i%2 === 0 ? 'fadeInLeft': 'fadeInRight'} className="my-2 overflow-hidden" animateOnce>
                            <Card className="w-100 mb-2 border-0 p-5 review-card">
                                <Card.Body className="p-xl-2 p-lg-0">
                                    <div className="reviews-content">
                                        <a className="star">
                                            {starRating(wordToNumber[review.starRating])}
                                        </a>
                                        <blockquote>
                                            <ReactReadMoreReadLess
                                                charLimit={300}
                                                readMoreText={"Read more"}
                                                readLessText={"Read less"}
                                                readMoreClassName="read-more-less"
                                                readLessClassName="read-more-less"
                                            >
                                                {review.comment}
                                            </ReactReadMoreReadLess>
                                        </blockquote>
                                        <div className="d-flex flex-row align-items-center">
                                            <img src={review.reviewer?.profilePhotoUrl} width={60}/>
                                            <div className="ml-3">
                                                <span className="customer-name">{review.reviewer?.displayName}</span>
                                                <span className="time">{moment(review.updateTime).startOf("day").fromNow()}</span>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </ScrollAnimation>
                    </Col>
                )}
            </Row>
            <div className="d-flex justify-content-center mb-5">
            {!isLess ? (
                <Button className="btn btn-secondary " onClick={loadMore}>
                    Load More
                </Button>
            ) : null}
        </div>
        </Container>
    )
}

export default Reviews;
